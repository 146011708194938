import axios from 'axios';
import {
	Toast
} from 'vant';

// 开发环境----------------------------------
// const dev = 'http://172.20.25.60:9090'; // 通用API请求地址 张
// const dev = 'https://reg38.szcreativeweek.com'; // 通用API请求地址
const dev = 'https://reg39.szcreativeweek.com'; // 通用API请求地址

// 测试环境-------------------------------------
// const prod = 'https://reg36test.szcreativeweek.com'; // 通用API请求地址

// 正式环境---------------------------------------
// const prod = 'https://reg37.szcreativeweek.com'; // 通用API请求地址
// const prod = 'https://reg38.szcreativeweek.com'; // 通用API请求地址
const prod = 'https://reg39.szcreativeweek.com'; // 通用API请求地址


const service = axios.create({
	baseURL: process.env.NODE_ENV === 'production' ? prod : dev,// 判断当前环境: 当前环境为生产环境时, 启用生产环境的接口路径; 否则启用开发环境的接口路径
	method: 'post',
	headers: {
		'content-type': 'application/json;charset=UTF-8', // JSON
	},
	timeout: 10000,
})

// request 请求拦截器
service.interceptors.request.use(config => {
	// if (!config.headers.hasOwnProperty('content-type')) {}
	config.headers['unionid'] = localStorage.getItem('unionid')
	// config.headers['AuthorizationToken'] = '';
	// config.headers['Accept-Language'] = localStorage.getItem('lang') || 'zh';

	return config;
}, error => {
	console.log('requestError: ', error);
	return Promise.reject(error);
})

// res 响应拦截器
service.interceptors.response.use(response => {
	const { status, data } = response;
	if (status === 200) {
		if (data.code == 200) {
			return response.data;                                                                                              
		} else {
				Toast(data.message);
				return Promise.reject(data);
		}
		
	} else {
		return Promise.reject(new Error());
	}
}, error => {
	console.log('error: ', error);
	console.log('errorResponse: ', error.response);
	console.log('errorResponseData: ', error.response.data);
	
	// if (error.toString().indexOf('Error: timeout') !== -1) {
	if (error.message.includes('timeout')) {
		Toast('网络请求超时');
	} 
	
	// if (error.toString().indexOf('Error: Network Error') !== -1) {
	if (error.message.includes('Network Error')) {
		Toast('网络请求错误');
	} 
	
	if (error.response && error.response.status) { // Internal Server Error
		
		const errorStatusCode = require('@/api/config/errorStatusCode.js');
		const errorTips = errorStatusCode['zh'][error.response.status];
		errorTips && Toast(errorTips);
	}
	
	return false;
})

export default service;
