// export const ERROR_MESSAGE_MAPS = {
export default {
  zh: {
    400: '请求错误，请检查参数',
    401: '未授权，请确认是否登录',
    403: '无权限，禁止访问',
    404: '接口或资源不存在',
    405: '请求方式不允许',
		408: '请求超时',
    413: '资源过大',
    414: 'URI过长',
    500: '服务器内部错误',
    502: '网关错误',
		503: '服务不可用',
    504: '网关超时',
  }
};